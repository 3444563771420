<div class="loading-item" [style]="setInnerGap">
    <div class="loadingLine lineA" [style]="setlineGap">
        <div class="graph" [style]="setLineSize"></div>
    </div>
    <div class="loadingLine lineB" [style]="setlineGap">
        <div class="graph" [style]="setLineSize"></div>
    </div>
    <div class="loadingLine lineC" [style]="setlineGap">
        <div class="graph" [style]="setLineSize"></div>
    </div>
</div>