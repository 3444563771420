import { Component, Input } from '@angular/core';

@Component({
  selector: 'asnt-loading-line',
  standalone: true,
  imports: [],
  templateUrl: './asnt-loading-line.component.html',
  styleUrl: './asnt-loading-line.component.less'
})
export class AsntLoadingLineComponent {

  @Input() innerGap: Array<number> = [];
  @Input() lineSize: number = 8;
  @Input() lineGap: number = 8;
  @Input() cover: boolean = false;
  
  setInnerGap!: string;
  setLineSize!: string;
  setlineGap!: string;

  ngOnInit() {
    this.buildStyle(this.innerGap, this.lineSize, this.lineGap);
  }
  buildStyle(gap: Array<number>, ls: number, lg: number) {
    if (gap.length != 0) {
      this.setInnerGap = `padding-top: ${gap[0]}px; padding-right: ${gap[1]}px; padding-bottom: ${gap[2]}px; padding-left: ${gap[3]}px;  `;
    }
    this.setLineSize = `height: ${ls}px; `
    this.setlineGap = `margin-bottom: ${lg}px; `

  }
}
